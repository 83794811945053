import React from "react"
import SEO from "../../components/seo"
import Header from "../../components/header"

import WorkFooter from "../../components/portfolioItems/worksFooter"


import Herospace from "../../../assets/images/portfolio_items/project_firefly/projecfirefly.png"
import projecfirefly1 from "../../../assets/images/portfolio_items/project_firefly/projecfirefly1.png"
import projecfirefly2 from "../../../assets/images/portfolio_items/project_firefly/projecfirefly2.png"
import projecfirefly3 from "../../../assets/images/portfolio_items/project_firefly/projecfirefly3.png"
import projecfirefly4 from "../../../assets/images/portfolio_items/project_firefly/projecfirefly4.png"

export default () => 
<div>
    <SEO title={'ICON Worldwide | Project Firefly - Recruitment Platform'}
    description="Discover more about Project Firefly's merit-based recruitment platform, a joint IT project between Credit Suisse and the University of St.Gallen!"
    canonical={'https://icon-worldwide.com/works/project-firefly'} />
        <Header bgcolor="#292929"/>
    <div id="portfolio-item">
        <div className="herospace">
            <img src={Herospace} alt=" recruitment platform, ICON Worldwide portfolio, Project Firefly" title="ICON Worldwide portfolio, recruitment platform, Project Firefly"/>
            <div className="title"><h1>Project Firefly<span></span></h1>
            <h2>merit-based recruitment platform</h2>
            </div>
        </div>
        <div className="portfolio-inner">
        <p>Developed as a joint project between Credit Suisse and the University of St.Gallen, Project Firefly is a merit based
        recruiting platform. Students from around the world submit essays which are reviewed by an international panel of university faculty. 
        Students are recognised with internship opportunities. ICON Worldwide developed the brand, website concept in Drupal, and works as IT project manager maintaining the platform.</p>
        <div className="portfolio-images">
            <img src={projecfirefly1} alt=" recruitment platform, ICON Worldwide portfolio, Project Firefly" title="ICON Worldwide portfolio, recruitment platform, Project Firefly"/>
            <img src={projecfirefly4} alt=" recruitment platform, ICON Worldwide portfolio, Project Firefly" title="ICON Worldwide portfolio, recruitment platform, Project Firefly"/>
            <img src={projecfirefly2} alt=" recruitment platform, ICON Worldwide portfolio, Project Firefly" title="ICON Worldwide portfolio, recruitment platform, Project Firefly"/>
            <img src={projecfirefly3} alt=" recruitment platform, ICON Worldwide portfolio, Project Firefly" title="ICON Worldwide portfolio, recruitment platform, Project Firefly"/>
        </div>
        <WorkFooter previous="residenz-forch" next="iron-deficiency-day"/>
        </div>
    </div>
</div>